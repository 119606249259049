<template>
  <div class="container-fluid page_container" >
      <div class="row justify-content-center pt-4">
          <div class="text-center">
            <img src="../../assets/rsz_afcfta_link_2.png" height="100" alt="">
          </div>
      </div>

      <div class="row justify-content-center mt-5" v-if="currentUser.entities.length == 0">
          <div class="col-lg-6 col-xl-6 col-md-10 cardOne" >
                <div class="card pb-4">
                    <p class="p-4 dark text-center">
                        Please contact your System Administrator to upgrade your access to AfCFTA Link.
                    </p>
                     <button @click="home" class="btn-danger my-4 mx-5 py-2 myBtn">Go to Home</button>
                </div>
          </div>
      </div> 

      <div class="row justify-content-center my-5" v-if="currentUser.entities">
          <div class="col-lg-5 col-xl-5 col-md-6">
              <div class="card select-card">
                  <h3 class="text-center my-4">Select Account you want to Manage</h3>
                  <hr>
                  <div class="row">
                      <div class="col-12"  v-for="(entity, key) in currentUser.entities" :key="key" :value="entity">
                          <p class="entity-text" @click="getEntity(entity)">{{entity.entity.name}}</p>
                      </div>
                  </div>
                <button @click="home" class="btn-danger my-4 mx-5 py-2 myBtn">Go to Home</button>
              </div>
          </div>
      </div>

  </div>
</template>

<script>

export default {
    methods: {
        home() {
            this.$router.replace('/')
        },
        getEntity(entity) {
            console.log('entity', entity)
            if(entity.role == 'Admin' || entity.role == 'subadmin') {
                this.$store.dispatch('setCurrentEntity',entity)
                    .then( () => {
                        this.$router.push('/entity/dashboard')
                    })
            } else {
                alert('Please contact your System Administrator to upgrade your access to AfCFTA Link')
            }
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters.currentUser
        }
    },
    created() {
        console.log('user', this.currentUser)
    }
}
</script>

<style scoped>

.page_container{
      background: #ba2428;
      width:100%;
      height: 100vh;
}
.dark{
    color:#000;
    font-size:1.4em;
}
.cardOne{
    height:500px !important;
}
.spinner {
    padding-top:200px;
}
.myBtn{
    font-weight: bold;
    font-size: 1.2em;
    background-color:#ba2428;

}

h3{
    color:#000;
}
.entity-text{
    text-align: center;
    /* background-color:rgb(170, 165, 165); */
    padding-top:10px;
    padding-bottom:10px;
    font-size: 1.3em;
    color:#111111;
    transition:all 0.5s;
}
.entity-text:hover {
    background-color: rgb(170, 165, 165);
    cursor: pointer;
}
</style>